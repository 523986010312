* {
	box-sizing: border-box;
}

.body {
	margin: 0;
}

section {
	display: flex;
	height: 100vh; 
}

section h2 {
	margin: 0;
}

.left-section {
	flex: 1;
	padding: 20px;
	margin: 20px;
	font-size: 20px;
	text-align: center;

}

.right-section {
	flex: 2;
	padding: 20px;
	margin: 20px;
	font-size: 20px;

}