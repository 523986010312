
.nav {
	background-color: #FA8072;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	gap: 2rem;
	padding: 0 1rem;
}

.site-title {
	font-size: 2rem;
}

nav ul {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	gap: 1rem;
}

nav li:active {
	background-color: #555;
}

nav li:hover {
	background-color: #777;
}

.nav a {
	color: inherit;
	text-decoration: none;
	height: 100%;
	display: flex;
	align-items: center;
	padding: .25rem;
}

